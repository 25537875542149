<template>
  <q-form
    v-bind="$attrs"
    autofocus
    class="q-gutter-md"
  >
    <q-input
      v-model="innerValue.name"
      :rules="[
  value => !!value || 'This field is required',
]"
      hint="The name of the FSEconomy group"
      label="Name"
      name="name"
      type="text"
    />
    <q-input
      v-model="innerValue.slug"
      :prefix="`${WEB_URL}/group/`"
      :rules="[
  value => !!value || 'This field is required',
  value => /^[a-z0-9][a-z0-9-]*[a-z0-9]$/.test(value) || 'This field must contain only lowercase alphabets (a-z), numbers (0-9), and/or dashes (-) and must neither begin nor end with a dash',
]"
      hint="The public link to view this group"
      label="Public page URL"
      name="slug"
      type="text"
    />
    <q-input
      v-model="innerValue.fseconomyAccessKey"
      :rules="[
  value => !!innerValue.id || !!value || 'This field is required',
  value => 'null' !== value || 'Access key must not be null, click the Reset button next to access key in FSEconomy\'s data feeds page to get a 16-character access key',
]"
      bottom-slots
      counter
      label="FSEconomy group read access key for FBO facilities"
      maxlength="16"
      name="fseconomyAccessKey"
      type="text"
    >
      <template v-slot:hint>
        <div>
          A 16-character key found next to your username or group in
          <a
            href="https://server.fseconomy.net/datafeeds.jsp"
            target="_blank"
          >the data feeds page</a>.
        </div>
      </template>
    </q-input>
    <q-input
      v-model="innerValue.description"
      :rules="[
  value => !value || value.length <= 1024 || 'This field must be 1024 characters or less',
]"
      autogrow
      label="Description"
      name="description"
      type="textarea"
    >
      <template v-slot:hint>
        <div>
          The description for this FSEconomy group in 1024 characters or less,
          supports
          <a
            href="https://www.markdownguide.org/cheat-sheet/"
            target="_blank"
          >Markdown syntax</a>
        </div>
      </template>
    </q-input>
  </q-form>
</template>

<script>
  export default {
    computed: {
      WEB_URL: {
        get () {
          return process.env.WEB_URL
        },
      },
    },
    methods: {
      async onSubmitClick () {
        await this.submit()
      },
    },
    props: {
      value: {
        type: Object,
      },
    },
    watch: {
      'innerValue.name': {
        handler (value) {
          if (!value) {
            return
          }

          this.$set(
            this.innerValue,
            'slug',
            this.$slug(value),
          )
        },
      },
      innerValue: {
        deep: true,
        handler (value) {
          this.$emit(
            'input',
            value,
          )
        },
      },
      value: {
        deep: true,
        immediate: true,
        handler (value) {
          this.innerValue = value
        },
      },
    },
    data () {
      return {
        innerValue: {},
      }
    },
  }
</script>
